import React from 'react'

// Data
import staffData from '../content/Staff-Content.json'

// Elements
import { Par } from '@theme'
import { Layout } from '@layouts'
import { Artical } from '@sections'
import { EmployeeCard, CardWrapper, EmployeeFactoryCard } from '@components'

const About = (props) => (
	<Layout props={props}>
		<Artical
			title="The Company"
			par={`
				Pumptec was formed in 1975 to supply the chemical and petrochemical
				industries in Southern Africa with centrifugal and positive displacement
				pumps. The Company is proudly owned by brothers, Alan and Richard Bullock.
			`}
		>
			<Par clr="grey" marginTop="1em">
				Our policy has always been to represent world class manufacturers in this
				region, enabling us to combine our many years of experience with quality
				equipment. Our products are sourced from Europe, the USA, Asia as well as
				locally and we produce the complete sets in our Johannesburg workshop.
			</Par>
			<Par clr="grey" marginTop="1em">
				Over the years our product range has expanded steadily, and we now have one
				of the most comprehensive ranges of pumps, flow meters and blending
				equipment in Southern Africa. Our centrifugal pumps range includes process
				quality, multistage, self priming, sealless and drum pumps in most alloys
				and non-metallic materials. Our range of positive displacement pumps
				includes gear, screw and rotary vane pumps, again in most alloys.
			</Par>
			<Par clr="grey" marginTop="1em">
				In addition to these pumps, we have also added a full range of flow meters,
				including positive displacement (SABS approved), nutating disc, turbine and
				mag flow type. We also supply Pulsair blending systems for difficult
				blending applications. With this wide range of products, we are able to
				assist with almost any requirement of the petrochemical and general
				industries.
			</Par>
			<Par clr="grey" marginTop="1em">
				Our customer list includes all the major oil companies in Southern Africa as
				well as a wide spectrum of industrial and mining companies throughout the
				region. In addition to the supply of equipment, we also offer a full repair
				facility of all equipment supplied by us and assistance with the design of
				fluid systems.
			</Par>
		</Artical>
		<Artical title="Our Team" theme="chalk">
			<CardWrapper marginTop="2em" colRowCount={3}>
				{staffData.office.map((staff) => (
					<EmployeeCard
						id={staff.id}
						key={staff.key}
						img={`${staff.img}.jpg`}
						title={staff.fullName}
						position={staff.position}
						email={staff.email}
					/>
				))}
			</CardWrapper>
			<CardWrapper marginTop="2em" colRowCount={3}>
				{staffData.factory.map((staff) => (
					<EmployeeFactoryCard
						key={staff.key}
						img={`${staff.img}.jpg`}
						title={staff.title}
						team={staff.team}
					/>
				))}
			</CardWrapper>
		</Artical>
	</Layout>
)

export default About
